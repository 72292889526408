<template>
  <div class="user-my-course" v-wechat-title="'我的课程'">
    <van-list
      v-if="courseList != ''"
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div class="user-my-course-list">
        <div v-for="(item,index) in courseList" :key="index" @click="$router.push(`/course/info?id=${item.id}&chapterId=${item.chapterId}`)">
          <span>
<!--            <img src="@/assets/img/111@2x.png" alt="course" />-->
            <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/111@2x.png')" alt="course" />
          </span>
          <div>
            <span>{{ item.courseName }}</span>
            <p>
              <span>共{{ item.chapterTotal || 0 }}节课</span>
              <span>{{  item.viewed }}人浏览</span>
            </p>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty
      v-if="isEmpty"
      class="empty-image"
      :image="require('@/assets/img/4886@2x.png')"
      description="暂无更多数据"
    />
  </div>
</template>

<script>
import { getCourse } from '@/api/activity'
import VImg from '@/components/VImg'

export default {
  components: { VImg },
  data () {
    return {
      page: {
        current: 1,
        size: 10,
        pageSize: 1
      },
      loading: false,
      finished: false,
      courseList: [],
      isEmpty:false
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onLoad () {
      if (!this.courseList || !this.courseList.length) {
        this.finished = true
        return
      }
      if (this.page.pageSize === this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadData()
    },
    // 解决方案列表
    loadData () {
      const { page } = this
      const data = {}
      getCourse(page, data).then((res) => {
        this.loading = false
        this.courseList = [...this.courseList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false
        if(this.courseList == ''){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-my-course{
  padding: 16px;
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  .user-my-course-list{
    >div{
      background: #FFFFFF;
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 10px;
      display: flex;
      &:last-child{
        margin-bottom: 0;
      }
      >span{
        width: 140px;
        height: 78px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
        >img{
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      >div{
        flex: 1;
        >span{
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          height: 40px;
          overflow: hidden;
          word-break: break-all;
        }
        >p{
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          >span{
            font-size: 12px;
            line-height: 17px;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
<style>
.empty-image .van-empty__image{
  width: 94px;
  height: 81px;
}
</style>
